<template>
    <div class="bg">
        <NavBar :logo="logo_path" :alt="app_name" />
    </div>
    <router-view/>
    <Footer />
  </template>
  
  <script>
    import NavBar from './components/NavBar.vue'
    import Footer from './components/Footer.vue'
  
    export default {
      components: {
        NavBar,
        Footer
      },
      data() {
        return {
          logo_path: "/img/logo-bourbonshopping-branco.png",
          app_name: "Bourbon Shopping"
        }
      }
    }
  </script>
  
  <style>

    .bg {
        background-color: #ED008C;
        border-bottom: 15px solid #d4007e;
    }

    .bg:nth-child(2n) {
        border-bottom: 35px solid #d4007e;
        margin-top: -35px;
    }

    .container {
        margin: 0 auto;
        max-width: 1000px;
        padding-bottom: 24px;
    }

      .main {
          margin: 0 auto;
          max-width: 1000px;
          margin-top: 1px;
      }
  
      .clearfix {
          clear: both;
      }
  
      .content {
          margin: 22px 0 1.714285714rem 0;
      }
  
      .entry-header {
          margin-bottom: 1.714285714rem;
          position: relative;
      }
  
      .entry-header .entry-title {
          height: 79px !important;
          padding-top: 97px;
          overflow: hidden;
          font-size: 85px !important;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: -8px;
          background-color: #1F2323;
          color: #a5a5a5;
          -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
          filter: alpha(opacity=80);
          -moz-opacity: .8;
          -khtml-opacity: .8;
          opacity: .8;
          line-height: 1.2;
      }
  
      .entry-header .entry-title-mobile {
          color: #fff;
          font-size: 18px;
          text-align: center;
          display: none;
      }
  
      .hmed {
    font-family: 'Raleway', sans-serif;
          font-weight: 400;
      }
  
      .hlight {
        font-family: 'Raleway', sans-serif;
        font-weight: 200;
      }
  
      .page #content {
          position: relative
      }
  
      .clear:after,
      .clear:before,
      .format-status .entry-header:after,
      .format-status .entry-header:before,
      .wrapper:after,
      .wrapper:before {
          display: table;
          content: ""
      }
  
      .clear-10,
      .clear:after,
      .entry-meta,
      .fix,
      .format-status .entry-header:after,
      .site-content nav,
      .wrapper:after,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
          clear: both
      }
  
      .site-content {
          margin: 1.714285714rem 0
      }
  
      #shopping-topo {
          margin: 0 60px 0 100px;
          min-height: 340px;
          display: block
      }
  
      .shopping-topo-intro p {
          margin-bottom: 20px;
          font-size: 12px;
          line-height: 18px;
          color: black
      }
  
      .shopping-topo-horarios p {
          color: black;
          line-height: 14px;
      }
  
      .shopping-topo-horarios strong {
          color: black;
          float: left;
          margin-top: 5px;
          width: 100%
      }
  
      .conteudo-pagina_ p,
      .shopping-topo {
          color: #fff!important;
          font-size: 12px!important;
          line-height: 18px!important
      }
  
      #shopping-meio {
          margin: 10px 0 20px;
          width: 980px;
          display: block
      }

      .shopping-meio {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
      }
  
      #shopping-meio p {
          line-height: 14px;
          margin: 0!important
      }
  
      .shopping-meio-servicos {
          width: 300px;
          float: left;
          text-align: center
      }
  
      .shopping-meio-info2 {
          width: 400px;
          padding: 10px 0;
          font-size: 11px;
          line-height: 14px;
          float: left;
          margin: 10px 0 0;
          height: 40px
      }
  
      .shopping-mapa {
          margin-top: 16px;
          clear: both;
          width: 100%!important;
          height: 360px
      }
  
      .shopping-mapa iframe {
          position: relative;
          width: 100%!important;
          height: 100%!important
      }
  
      #shopping-baixo {
          width: 980px;
          margin-bottom: 30px
      }
  
      .shopping-baixo-fotos {
          width: 530px;
          float: left;
          font-family: 'Raleway', sans-serif;
          font-weight: 800;
      }
  
      .shopping-baixo-fotos p {
          margin: 0;
          cursor: pointer;
      }
  
      .shopping-baixo-fotos a.localizacao {
          /* background: url('https://media-cdn.tripadvisor.com/media/photo-s/1a/30/fa/ad/janeiro-restaurant-ocean.jpg'); */
          background-color: gray;
          width: 130px;
          display: block;
          height: 35px;
          padding: 60px 0 0 10px;
          color: #FFF;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 800px;
          float: left;
          font-size: 16px;
          letter-spacing: -1px
      }
  
      .wp-caption {
          max-width: 100%;
          padding: 4px
      }
  
      .shopping-baixo-fotos .wp-caption {
          cursor: pointer;
          max-width: 100%;
          padding: 0;
          float: left;
          background: #c8c8c8;
          width: 80px!important;
          margin: 7px 0 0 7px
      }
  
  
      @media (max-width: 992px) {
          .main {
              margin-top: 0;
          }
  
          .content {
              margin-top: 0;
          }
  
          .entry-header .entry-title {
              display: none;
          }
  
          .entry-header .entry-title-mobile {
              display: block;
          }
      }
  
      .content-text {
          width: 400px;
          margin-left: 100px;
      }
  
      .content-text p {
          color: black !important;
          font-size: 12px !important;
          line-height: 18px !important;
          margin-bottom: 20px;
      }
  
      @media (max-width: 1024px) {
          .content-text {
              width: 100%;
              margin: 0 auto;
          }
      }
  
      /* @charset "UTF-8"; */
      @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
  
      a,
      abbr,
      acronym,
      address,
      applet,
      article,
      aside,
      audio,
      b,
      big,
      blockquote,
      body,
      canvas,
      caption,
      center,
      cite,
      code,
      dd,
      del,
      details,
      dfn,
      div,
      dl,
      dt,
      em,
      embed,
      fieldset,
      figcaption,
      figure,
      footer,
      form,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      header,
      hgroup,
      html,
      i,
      iframe,
      img,
      ins,
      kbd,
      label,
      legend,
      li,
      mark,
      menu,
      nav,
      object,
      ol,
      output,
      p,
      pre,
      q,
      ruby,
      s,
      samp,
      section,
      small,
      span,
      strike,
      strong,
      sub,
      summary,
      sup,
      table,
      tbody,
      td,
      tfoot,
      th,
      thead,
      time,
      tr,
      tt,
      u,
      ul,
      var,
      video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          vertical-align: baseline;
      }
  
      embed,
      iframe,
      object,
      video {
          max-width: 100%
      }
  
      html {
          max-width: 100vw;
          font-size: 100%;
          overflow-y: scroll;
          overflow-x: hidden !important;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
      }
  
      body {
          line-height: 1;
          min-width: 1000px !important;
          background-color: #ffffff;
          font-size: 12px;
          font-family: Arial, Helvetica, sans-serif;
          text-rendering: optimizeLegibility;
          color: #000;
      }
  
      @media (min-width: 1024px) {
          body.bg-custom {
              background-position: center -705px !important;
          }
  
          body.bg-custom2 {
              background-position: center -570px !important;
          }
      }
  
      ol,
      ul {
          list-style: none
      }
  
      blockquote,
      q {
          quotes: none;
      }
  
      blockquote:after,
      blockquote:before,
      q:after,
      q:before {
          content: '';
          content: none;
      }
  
      table {
          border-collapse: collapse;
          border-spacing: 0;
      }
  
      caption,
      td,
      th {
          font-weight: 400;
          text-align: left;
      }
  
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {

          font-family: 'Raleway', sans-serif !important;
          font-weight: 800;
      }
  
      a:focus {
          outline: dotted thin;
      }
  
      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      nav,
      section {
          display: block;
      }
  
      #v {
          display: none;
      }
  
      /* responsive.min.css */  
      @media only screen and (max-width:1024px) {
          #capa-topo {
              z-index: 1
          }
  
          body {
              min-width: 100% !important
          }
  
          .hfeed .wrapper .nivo-controlNav {
              z-index: 10
          }
  
          .hfeed .slider-wrapper {
              margin: -30px 0 -10px 0
          }
  
          .site-header {
              padding: 15px 0 0;
              position: relative;
          }
  
          .site-header .menu-menu-principal-container {
              display: none;
          }
  
          .site-header .menu-menu-principal-container.show {
              display: block;
          }
  
          .main-navigation.show {
              display: block;
              right: 0 !important;
          }
  
          .site-header h1 {
              width: 100%;
          }
  
          .site-header h1 a {
              width: 215px;
              position: absolute;
              background-size: contain !important;
              left: 50%;
              top: 7px;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);
          }
  
          .site-header .main-navigation {
              float: none;
              margin-top: 0
          }
  
          .site-header .container-escolha {
              width: 200px;
              display: inline-block;
              margin: 0 auto;
              text-align: center;
              z-index: 1000;
          }
  
          .site-header .container-escolha .linkhover {
              width: 200px;
              padding: 10px 0;
              box-sizing: border-box;
          }
  
          .site-header .container-escolha .linkhover span {
              width: 190px;
              padding-left: 10px;
              box-sizing: border-box;
              /* display: inline-block; */
              font-style: initial;
              font-size: 13px
          }
  
          .site-header .top-navigation {
              width: 100px;
              float: right;
              margin: 10px auto 0;
              text-align: right;
              display: none
          }
  
          .site-header .site-title {
              display: block;
              margin: 0 auto
          }
  
          .site-header .nav-menu li {
              display: block;
              padding: 8px 0;
              margin: 0;
              border-bottom: solid 1px #f296f1;
          }
  
          .site-header .nav-menu li:last-child {
              border: 0
          }
  
          .h-height {
              height: 55px
          }
  
          hgroup {
              margin-bottom: 15px
          }
      }
  
      @media only screen and (max-width:480px) {
          header {
              margin-bottom: 15px
          }
  
          .site-header h1 a {
              width: 156px
          }
      }
  
      @media (min-width:1025px) {
          .site-header .menu-menu-principal-container {
              display: block !important
          }
      }
  
      .site-header .container-escolha .linkhover img {
          margin-left: 10px;
          display: inline-block
      }
  
      @media only screen and (max-width:1024px) {
          body .site {
              padding: 0 !important;
          }
  
          body .container-escolha {
              opacity: 1 !important;
              width: 100% !important;
          }
  
          body .container-escolha .linkhover {
              width: 100% !important;
              border-radius: 0 !important;
              position: relative !important;
          }
  
          body .container-escolha .linkhover ul li a,
          body .container-escolha .linkhover ul li h4 {
              padding: 20px 10px 20px 10px;
              display: block;
              text-decoration: none;
          font-family: 'Raleway', sans-serif !important;
          font-weight: 200;              
          font-style: normal;
              color: #fff;
              font-size: 12px;
              text-transform: inherit;
              border-top: 1px solid #fff;
          }
  
          body .container-escolha .linkhover span {
              margin-bottom: 0 !important;
              font-family: 'Raleway', sans-serif !important;
              font-weight: 200;
          }
  
          body .container-escolha .linkhover img {
              display: none !important
          }
  
          body.active-menu {
              position: relative;
          }
  
          body.active-menu .site {
              padding: 0 !important
          }
  
          body.active-menu .main-navigation {
              position: fixed;
              top: 0;
              background: #fff;
              width: 40%;
              height: 100%;
              right: -40%;
              z-index: 100000;
          }
  
          body.active-menu .main-navigation a {
              background: none;
              color: #ef2c88 !important;
              font-family: 'Raleway', sans-serif !important;
              font-weight: 400;
              text-indent: 0;
              width: 100%;
              padding: 8px 40px;
              text-align: left;
              outline: none
          }
  
          body.active-menu .main-navigation .current-menu-item,
          body.active-menu .main-navigation .current-menu-ancestor,
          body.active-menu .main-navigation .current_page_item,
          body.active-menu .main-navigation .current_page_ancestor {
              background: #ececec;
              color: #ef2c88
          }
  
          #v {
              width: auto !important;
              margin-left: 7px !important;
              display: inline-block;
              padding-left: 0 !important
          }
  
          .page-shopping{
              background:#fff;
          }
      }
  
      @media only screen and (max-width:768px) and (min-width:480px) {
          body .site {
              padding: 0 !important;
          }
  
          body .site .btn-menu {
              right: 0
          }
  
          body .container-escolha {
              opacity: 1 !important;
          }
  
          body .container-escolha .linkhover {
              border-radius: 0 !important;
          }
  
          body .container-escolha .linkhover ul li a,
          body .container-escolha .linkhover ul li h4 {
              padding: 20px 10px 20px 10px;
              display: block;
              text-decoration: none;
              font-family: 'Raleway', sans-serif !important;
              font-weight: 200;
              font-style: normal;
              color: #fff;
              font-size: 12px;
              text-transform: inherit;
              border-top: 1px solid #fff;
          }
  
          body .container-escolha .linkhover span {
            font-family: 'Raleway', sans-serif !important;
            font-weight: 200;
          }
  
          body.active-menu {
              position: relative;
          }
  
          body.active-menu .site {
              padding: 0 !important
          }
  
          body.active-menu .main-navigation {
              position: fixed;
              top: 0;
              background: #fff;
              width: 40%;
              height: 100%;
              right: -40%;
          }
  
          body.active-menu .main-navigation a {
              background: none;
              color: #ef2c88 !important;
              font-family: 'Raleway', sans-serif;
              font-weight: 400;
              text-indent: 0;
              width: 90%;
              padding: 8px 40px;
              text-align: left;
              outline: none
          }
  
          body.active-menu .main-navigation .current-menu-item,
          body.active-menu .main-navigation .current-menu-ancestor,
          body.active-menu .main-navigation .current_page_item,
          body.active-menu .main-navigation .current_page_ancestor {
              background: #ececec;
              color: #ef2c88
          }
      }
  
      @media only screen and (max-width:480px) {
          body .site {
              padding: 0 !important
          }
  
          body .container-escolha {
              opacity: 1 !important;
          }
  
          body .container-escolha .linkhover {
              border-radius: 0 !important;
          }
  
          body .container-escolha .linkhover span {
    font-family: 'Raleway', sans-serif !important;
    font-weight: 200;
              position: relative;
              padding: 0 !important;
          }
  
          body .container-escolha .linkhover span img {
              display: none !important
          }
  
          body .container-escolha .linkhover ul li a,
          body .container-escolha .linkhover ul li h4 {
              padding: 20px 10px 20px 10px;
              display: block;
              text-decoration: none;
              font-family: 'Raleway', sans-serif !important;
              font-weight: 200;
              font-style: normal;
              color: #fff;
              font-size: 12px;
              text-transform: inherit;
              border-top: 1px solid #fff;
          }
  
          body.active-menu {
              position: relative;
          }
  
          body.active-menu .site {
              padding: 0 !important
          }
  
          body.active-menu .main-navigation {
              position: fixed;
              top: 0;
              background: #fff;
              width: 50%;
              height: 100%;
              right: -50%;
          }
  
          body.active-menu .main-navigation a {
              background: none;
              color: #ef2c88 !important;
              font-family: 'Raleway', sans-serif !important;
              font-weight: 400;
              text-indent: 0;
              width: 80%;
              padding: 8px 20px;
              text-align: left;
              outline: none;
              white-space: pre-wrap;
              height: 100%
          }
  
          body.active-menu .main-navigation .current-menu-item,
          body.active-menu .main-navigation .current-menu-ancestor,
          body.active-menu .main-navigation .current_page_item,
          body.active-menu .main-navigation .current_page_ancestor {
              background: #ececec;
              color: #ef2c88
          }
      }

  
  
  
      @media (max-width:480px) {

          .container-escolha .linkhover span {
              font-size: 9px !important
          }
      }
  
      @media (max-width:320px) {

          .container-escolha .linkhover span {
              font-size: 9px !important
          }
      }
  
      @media (max-width:1024px) {
          #capa-topo .bg {
              padding-bottom: 0
          }
      }
  
      @media only screen and (max-width:1024px) and (min-width:767px) and (max-width:480px) {
          .bt_shopping_ {
              width: 48% !important;
              margin: 1% !important
          }
      }
  
      @media only screen and (max-width:767px) and (min-width:481px) and (max-width:480px) {
          .bt_shopping_ {
              width: 48% !important;
              margin: 1% !important
          }
      }
  
      @media only screen and (max-width:480px) and (max-width:480px) {
          .bt_shopping_ {
              width: 48% !important;
              margin: 1% !important
          }
      }
  
  </style>
  