<template>
    <div class="footer-top"></div>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-left">
                    <div class="f-item">
                        <h4>ESCOLHA O SEU</h4>
                        <ul>
                            <li>
                                <RouterLink to="/o-shopping" id="0">Todos os shoppings</RouterLink>
                            </li>
                            <li v-for="shop in shoppings">
                                <RouterLink :to="`/o-shopping/${shop.id}`" :id="shop.id">Bourbon {{ shop.titulo }}</RouterLink>
                            </li>
                            <li><a class="no" target="_blank" rel="noopener noreferrer" href="https://www.portoalegrecenterlar.com.br/" id="">Porto Alegre CenterLar</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-right">
                    <div class="f-item">
                        <h4>SIGA O BOURBON</h4>

                        <h4>RS</h4>
                        <a href="https://www.facebook.com/bourbonshopping" target="_blank" rel="noopener noreferrer" class="f-icon facebook">Facebook</a>
                        <a href="https://www.instagram.com/bourbonshopping/" target="_blank" rel="noopener noreferrer" class="f-icon instagram">Instagram</a>
                        <div class="clearfix"></div>

                        <h4>SP</h4>
                        <a href="https://www.facebook.com/bourbonshoppingsp" target="_blank" rel="noopener noreferrer" class="f-icon facebook">Facebook</a>
                        <a href="https://www.instagram.com/bourbonshoppingsp/" target="_blank" rel="noopener noreferrer" class="f-icon instagram">Instagram</a>
                        <div class="clearfix"></div>

                        <p>Acesso:
                            <a href="https://www.airaz.com.br/portallojista/" rel="noopener noreferrer" title="Portal Lojista" target="_blank">Portal Lojista</a>
                        </p>
                    </div>

                    <div class="f-item">
                        <a href="http://www.grupozaffari.com.br/portal-de-privacidade/" rel="noopener noreferrer" target="_blank">
                            <h4>PORTAL DE PRIVACIDADE</h4>
                        </a>
                        <a href="http://www.grupozaffari.com.br/portal-de-privacidade/politica-de-privacidade.php" rel="noopener noreferrer" target="_blank">
                            <h4>POLÍTICA DE PRIVACIDADE PARA SITES</h4>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a href="http://www.grupozaffari.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src="/img/grupo-zaffari.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { RouterLink } from 'vue-router';
import { shopping } from '../service/Shoppings';

export default {
    name: "Footer",
    mixins: [shopping],
    beforeMount() {
        this.getShoppings();
    },
    components: { RouterLink }
}
</script>

<style scoped>
footer {
    background: #404035;
    padding-top: 2.85rem;
}

footer .container {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    footer .container {
        width: 700px;
    }
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

footer .container:after,
footer .container:before,
footer .row:after,
footer .row:before {
    display: table;
    content: " ";
}

footer .container:after,
footer .row:after {
    clear: both;
}

footer .row {
    margin-right: -15px;
    margin-left: -15px;
}

.col-left,
.col-right,
.col {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.col-left {
    float: left;
}

.col-right {
    float: right;
}

.col-left .f-item {
    width: 180px;
    margin-left: 70px;
    padding-right: 80px;
    border-top: 1px solid #929292;
}

.col-right .f-item {
    width: 320px;
    border-top: 1px solid #929292;
    margin-bottom: 20px !important;
}

@media (max-width: 767px) {

    .col-left,
    .col-right {
        width: 50%;
    }

    .f-item {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.f-item h4 {
    margin: 11px 0 7px;
    color: #FFF;
    /* font-style: normal; */
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
}

.f-item p {
    color: #929292;
}

.f-item ul li {
    line-height: 1.8em;
}

.f-item a {
    color: #929292;
    text-decoration: none;
}

.f-item a:hover {
    text-decoration: underline;
}

.f-item .f-icon {
    width: 34px;
    height: 34px;
    display: block;
    float: left;
    margin-right: 8px;
    margin-bottom: 10px;
    text-indent: -9999px;
}

.f-icon .youtube {
    background: url('~@/assets/img/icon-social.png') no-repeat
}

.f-icon:hover.youtube {
    background: url('~@/assets/img/icon-social.png') 0 -34px no-repeat
}

.f-icon.wifi {
    background: url('~@/assets/img/icon-wifi.png') no-repeat
}

.f-icon:hover.wifi {
    background: url('~@/assets/img/icon-wifi.png') 0 -34px no-repeat
}

.f-icon.facebook {
    background: url('~@/assets/img/icon-social.png') -86px 0 no-repeat
}

.f-icon:hover.facebook {
    background: url('~@/assets/img/icon-social.png') -86px -34px no-repeat
}

.f-icon.twitter {
    background: url('~@/assets/img/icon-social.png') -42px 0 no-repeat
}

.f-icon:hover.twitter {
    background: url('~@/assets/img/icon-social.png') -42px -34px no-repeat
}

.f-icon.instagram {
    background: url('~@/assets/img/icon-instagram.jpg') no-repeat
}

.f-icon:hover.instagram {
    background: url('~@/assets/img/icon-instagram.jpg') 0 -40px no-repeat
}

.footer-top {
    height: 40px;
    background: rgba(0, 0, 0, 0.6)
}

.footer-bottom {
    height: 40px;
    background-color: #333;
    margin-top: 2.85rem;
}

.footer-bottom img {
    margin-top: 12px;
}

.footer-bottom .col {
    text-align: right;
}
</style>