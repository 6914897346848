import api from "../api";

export const novidades = {
    data () {
        return {
            searchNovidade: '',
            slug: '',
            novidadesLista: [],
            novidadesDestaque: [],
            novidadesBySlug: [],
            shoppings: [],
            categorias: [],
            pageSize: 12,
            shoppingsId: '',
            categoriasId: '',
            isLoading: true,
            acabouNovidades: false
        }
    },
    methods: {
        async getNovidades () {
            this.isLoading = true
            try {
                const {data} = await api.get(`bourbon-shopping/novidades?pageSize=${this.pageSize}&searchNovidade=${this.searchNovidade}&categoria=${this.categoriasId}&shopping=${this.shoppingsId}`)
                this.novidadesLista = data.items.slice(0, this.pageSize)
                if(data.last_page <= 1) {
                    this.acabouNovidades = true
                }
            } catch (error) {
                console.log(error)
            }
            this.isLoading = false
        },

        async getNovidadeBySlug() {
            try {
                const {data} = await api.get(`bourbon-shopping/novidade?slug=${this.slug}`)
                this.novidadesBySlug = data
            } catch (error) {
                console.log(error)
            }
        },

        async getNovidadesDestaque () {
            try {
                const {data} = await api.get('bourbon-shopping/novidades?pageSize=3&destaques=1')
                this.novidadesDestaque = data.items
            } catch (error) {
                console.log(error)
            }
        },

        async getNovidadesCapaGeral () {
            this.isLoading = true
            try {
                const {data} = await api.get('bourbon-shopping/novidades?&capaGeral=1')
                this.novidadesLista = data.items
            } catch (error) {
                console.log(error)
            }
            this.isLoading = false
        },

        async getNovidadesShopping () {
            try {
                const {data} = await api.get('bourbon-shopping/novidades/shoppings')
                this.shoppings = data
            } catch (error) {
                console.log(error)
            }
        },
        
        async getNovidadesCategoria () {
            try {
                const {data} = await api.get('bourbon-shopping/novidades/categorias')
                this.categorias = data
            } catch (error) {
                console.log(error)
            }
        },
    }
}