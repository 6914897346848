<template> 

  <div
    v-if="isLoading"
    class="loading"
  >
      <ContentLoading 
          width="1000px"
          height="150px"
          animation-duration="2s"
      />
  </div>

  <swiper
    v-else
    :modules="modules"
    :slides-per-view="1"
    :space-between="30"
    :loop="true"
    :speed="1500"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
    navigation
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="banner in banners" >
      <a :href="banner.url ? banner.url : '#'" target="_blank">
        <img :src=banner.url_imagem alt="Imagem Banner">
      </a>
    </swiper-slide>
  </swiper>
</template>

<script>
  // import Swiper core and required modules
  import { Navigation, Autoplay, Pagination } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';

  import { banners } from '../service/Banners';
  import ContentLoading from './ContentLoading.vue';

  export default {
    components: {
    Swiper,
    SwiperSlide,
    ContentLoading
    },
    mixins: [ banners ],
    setup() {
      const onSwiper = (swiper) => {
      };
      const onSlideChange = () => {
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Autoplay, Pagination, Navigation],
      };
    },
    beforeMount() {
      this.getBanners()
    }
  };
</script>

<style>
    .swiper {
        display: flex;
        margin: auto;
        max-width: 1440px;
    }
    .swiper-slide img {
      width: 100%;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
      color: #FFF !important;
      background: #404444 ;
      padding: 8px;
      font-size: 42px
    }

    .loading {
      align-items: center;
    }

    @media(max-width: 960px) {
      .swiper-button-next::after, .swiper-button-prev::after {
        font-size: 24px;
      }
    }

    @media(max-width: 760px) {
      .swiper-button-next::after, .swiper-button-prev::after {
        font-size: 16px;
      }

      .swiper-button-next, .swiper-button-prev {
        padding: 0.5px;
      }
      
      .swiper {
        max-width: none;
        width: 100%;
      }
    }
</style>