<template>
  <div class="site-header">
    <div>
      <router-link to="/">
        <div class="logo">
          <img :src="logo" :alt="alt" id="logo" />
        </div>
      </router-link>
      <Dropdown class="dropdown">
        <template #selecionado>
          <h2 class="tituloDropdown">
            {{ itemAtivo
            }}<i class="fa fa-angle-down seta" aria-hidden="true"></i>
          </h2>
        </template>
        <template #itens>
          <li @click="adicionarStore('', 'Todos os shoppings')" class="lista">
            Todos os Shoppings
          </li>
          <li
            v-for="shopping in shoppings"
            class="lista"
            :key="shopping.id"
            @click="adicionarStore(shopping.id, shopping.titulo)"
          >
            <a href="#">{{ shopping.titulo }}</a>
          </li>
        </template>
      </Dropdown>
    </div>

    <nav aria-label="Navbar">
      <ul id="menu-desktop" class="menu-desktop">
        <li class="menu-item menu-item-type-custom menu-item-object-custom">
          <router-link to="/" active-class="active">Home</router-link>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page">
          <router-link to="/o-shopping" active-class="active"
            >O Shopping</router-link
          >
        </li>
        <li class="menu-item menu-item-type-custom menu-item-object-custom">
          <router-link to="/novidades" active-class="active"
            >Novidades</router-link
          >
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page">
          <router-link to="/cinemas" active-class="active">Cinemas</router-link>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page">
          <router-link to="/teatros" active-class="active">Teatros</router-link>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page">
          <router-link to="/lojas" active-class="active">Lojas</router-link>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page">
          <router-link to="/atendimento" active-class="active"
            >Atendimento</router-link
          >
        </li>
      </ul>
    </nav>

    <nav
      class="btn-menu-hamburguer"
      :class="{ open: isActive, close: isAfterLeave }"
      aria-label="Navbar Hamburguer"
    >
      <a
        href="#"
        class="btn-bars"
        @click="openMenu"
        v-if="!isActive && !isAfterLeave"
      >
        <em class="fa-solid fa-fw fa-bars"></em>
      </a>
      <Transition name="btnOpen">
        <a href="#" @click="openMenu" v-if="isActive">
          <em class="fa-solid fa-fw fa-xmark"></em>
        </a>
      </Transition>
      <Transition
        name="menuOpen"
        @after-leave="afterLeave"
        @after-enter="afterLeave"
      >
        <ul v-if="isActive">
          <li>
            <router-link to="/" @click="openMenu">Home</router-link>
          </li>
          <li>
            <router-link to="/o-shopping" @click="openMenu"
              >O Shopping</router-link
            >
          </li>
          <li>
            <router-link to="/novidades" @click="openMenu"
              >Novidades</router-link
            >
          </li>
          <li>
            <router-link to="/cinemas" @click="openMenu"
              >Filmes em cartaz</router-link
            >
          </li>
          <li>
            <router-link to="/teatros" @click="openMenu"
              >Espetáculo em cartaz</router-link
            >
          </li>
          <li>
            <router-link to="/lojas" @click="openMenu"
              >Guia de Lojas</router-link
            >
          </li>
          <li>
            <router-link to="/atendimento" @click="openMenu"
              >Atendimento</router-link
            >
          </li>
        </ul>
      </Transition>
    </nav>
  </div>
</template>

<script>
import { shopping } from "../service/Shoppings";
import Dropdown from "./Dropdown.vue";

export default {
  name: "NavBar",
  props: ["logo", "alt"],
  components: { Dropdown },
  mixins: [shopping],
  data() {
    return {
      isActive: false,
      isAfterLeave: false,
      itemAtivo: "Escolha o seu Bourbon",
    };
  },
  methods: {
    afterLeave() {
      this.isAfterLeave = !this.isAfterLeave;
    },
    openMenu() {
      this.isActive = !this.isActive;
      document.body.classList.toggle("active-menu");
    },
    adicionarStore(id, titulo) {
      this.itemAtivo = titulo;
      this.$router.push({ params: { id } });
      localStorage.setItem("shopping", id);
      localStorage.setItem("titulo", titulo);
    },
  },
  async beforeMount() {
    await this.getShoppings();
    if (localStorage.getItem("titulo")) {
      const titulo = localStorage.getItem("titulo");
      this.itemAtivo = titulo;
    }
  },
};
</script>

<style scoped>
.menuOpen-enter-active,
.btnOpen-enter-active {
  transform: translate(0px, 0px);
  transition: 1.5s;
}

.menuOpen-enter-from,
.btnOpen-enter-from {
  transform: translate(384px, 0px);
}

.menuOpen-leave-active,
.btnOpen-leave-active {
  transform: translate(0px, 0px);
  transition: 1.5s;
}

.menuOpen-leave-to,
.btnOpen-leave-to {
  transform: translate(364px, 0px);
  transition: 1.5s;
}

a:focus {
  outline: none;
}

.logo {
  float: left;
  width: 220px;
}

.logo img {
  width: 100%;
}

.menu-desktop {
  display: flex;
  font-family: "Raleway", sans-serif !important;
  font-weight: 800;
  font-size: 15px;
  gap: 16px;
}

.menu-desktop li a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}

.active,
.menu-desktop li a:hover {
  border-bottom: 5px solid white;
}

.site-header {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  z-index: 99;
}

.site-header > div:first-child {
  align-items: center;
  display: flex;
  gap: 16px;
}

.top-navigation {
  float: right;
  margin: 25px 3px 0 0;
  font-family: "Raleway", sans-serif !important;
  font-weight: 200;
  font-size: 11px;
  font-style: italic;
  color: #fff;
}

.top-navigation a {
  color: #fff !important;
  text-decoration: none;
}

.top-navigation a:hover {
  text-decoration: underline;
}
.btn-menu-hamburguer {
  display: none;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1000000;
}

.open {
  position: fixed;
  right: 0;
  top: 0;
}

.close {
  top: 0;
}

.btn-menu-hamburguer em {
  background: white;
  color: #ed008c;
  font-size: 32px;
  padding: 12px;
  width: 30px;
}

.btn-bars {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-menu-hamburguer ul {
  height: 100vh;
  width: 320px;
  background: white;
}

.btn-menu-hamburguer ul li {
  padding: 32px 60px 32px 16px;
  border-bottom: solid 1px #f296f1;
}

.btn-menu-hamburguer ul li a {
  color: #ef2c88;
  font-family: "Raleway", sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  outline: none;
}

@media (max-width: 1100px) {
  .menu-item-atendimento {
    display: none !important;
  }

  .menu-desktop {
    display: none;
  }

  .btn-menu-hamburguer {
    display: flex;
  }
}

@media (max-width: 768px) {
  .logo {
    float: none;
    width: 156px;
    margin: 0 auto;
    position: relative;
  }
}

@media (max-width: 520px) {
  .container-escolha {
    text-align: start;
    margin-top: 16px !important;
    width: fit-content !important;
  }
  .btn-menu-hamburguer em {
    padding: 10px;
    width: 30px;
  }

  .btn-menu-hamburguer ul {
    width: 250px;
  }
}

@media (max-width: 460px) {
  .site-header > div:first-child {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 0px;
  }

  .dropdown {
    height: 16px;
    margin: auto;
    text-align: center;
    padding: 0;
  }

  .dropdown .seta {
    font-size: 11px;
  }

  .dropdown .titulo {
    font-size: 10px;
  }
}
</style>