import axios from "axios";

const api = axios.create({
    baseURL: "/",
    headers: {
        'Content-Type': 'application/json'
    }
});

export const apiSalesforce = axios.create({
    baseURL: "/",
    headers: {
        'Content-Type': 'application/json'
    }
});

apiSalesforce.defaults.crossDomain = true;
apiSalesforce.defaults.withCredentials = true;
apiSalesforce.defaults.mode = 'no-cors';
apiSalesforce.defaults.headers.common.Accept = 'application/json';

export default api