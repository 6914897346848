<template>
    <div class="container-banner">
      <div class="content">
        <BannersHome />
      </div>
    </div>
    <div class="container">
      <div class="content">
        <NovidadesHome />
      </div>  
    </div>
</template>

<script>
  import NovidadesHome from "../components/NovidadesHome.vue";
  import BannersHome from "../components/BannersHome.vue";

  export default {
    name: "Home",
    title: 'Bourbon Shopping - Bourbon Shopping',
    components: { NovidadesHome, BannersHome }
  }
</script>

<style scoped>
  .content {
    margin: 0px 0;
  }

  .container-banner {
    padding: 0 30px;
    margin-top: 24px
  }

  @media(max-width: 768px) {
    .container-banner {
      padding: 0px;
    }
  }
</style>
