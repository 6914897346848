import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/o-shopping',
    name: 'Shopping',
    component: () => import('../views/Shopping.vue'),
    children: [
      { path: '/o-shopping/:id', name: 'ShoppingId', component: () => import('../views/Shopping.vue') }
    ]
  },
  {
    path: '/novidades',
    name: 'Novidades',
    component: () => import('../views/Novidades.vue'),
 
  },
  {
    path: '/novidades/:slug',
    name: 'Novidade',
    component: () => import('../views/Novidade.vue')
  },
  {
    path: '/cinemas',
    name: 'Cinemas',
    component: () => import('../views/Cinemas.vue')
  },
  {
    path: '/teatros',
    name: 'Teatros',
    component: () => import('../views/Teatros.vue')
  },
  {
    path: '/lojas',
    name: 'Lojas',
    component: () => import('../views/Lojas.vue'),
    children: [
      { path: '/lojas/:atividade', name: 'LojasAtividade', component: () => import('../views/Lojas.vue') }
    ]
  },
  {
    path: '/atendimento',
    name: 'Atendimento',
    component: () => import('../views/Atendimento.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'Home',
      path: '/',
    }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  fallback: true,
  duplicateNavigationPolicy: 'ignore',
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0)
  }
})

export default router
