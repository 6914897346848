import api from "../api";

export const shopping = {
    data () {
        return {
            shoppings: [],
            shoppingId: [],
            page: 0,
            pageSize: 20,
            isLoading: true
        }
    },
    methods: {
        async getShoppings () {
            this.isLoading = true
            try {
                const {data} = await api.get('bourbon-shopping/shoppings')
                this.shoppings = data.items
            } catch (error) {
                console.log(error)
            }
            this.isLoading = false
        },
        async getShoppingById (id) {
            try {
                const {data} = await api.get(`bourbon-shopping/shoppings/${id}`)
                this.shoppingId = data
            } catch (error) {
                console.log(error)
            }
        }
    }
}