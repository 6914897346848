import api from "../api";

export const banners = {
    data () {
        return {
            banners: [],
            isLoading: true
        }
    },
    methods: {
        async getBanners () {
            try {
                const {data} = await api.get('/bourbon-shopping/banners')
                for(let i = 0; i < data.length; i++) {
                    if(data[i].url_imagem !== null) {
                        this.banners.push(data[i])
                    }
                }
            } catch (error) {
                console.log(error)
            }
            this.isLoading = false
        }
    }
}